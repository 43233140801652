<template>
    <div class="flex gap-6 justify-center">
        <img
            class="w-auto"
            :src="isDark ? '/logo-white.svg' : '/logo.svg'"
            alt="Logo">
        <div class="leading-1 text-4xl flex items-center text-secondary">
            <span>
                BEMA
            </span>
        </div>
    </div>
</template>

<script setup>
let isDark = null;

if (useFeature('darkMode')) {
    isDark = useDark({
        disableTransition: false
    })
}
</script>